header {
  display: flex;
  justify-content: space-between;
  background-color: #6200ee;
  padding: 12px;

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: 400;
    color: #fff;


    strong {
      font-weight: 900;
      color: #fff;
    }
  }

  input {
    height: 75%;
  }

  .search {
    width: 30%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}