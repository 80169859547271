.map {
  flex-grow: 1;
  height: 100%;
}

.leaflet-container {
  height: 100%;
}

.leaflet-popup-content {

  h2 {
    color: #333;
  }

  p {
    color: #333;
  }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {}

a.leaflet-popup-close-button {
  color: #333;
  font-size: 1rem;
}