button,
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lighten(darkgreen, 5%);
  border: none;
  border-radius: 2px;
  padding: 5px 20px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 700;
  font-size: 0.8rem;
  cursor: pointer;
  transition: .3s;

  &:hover {
    background-color: darkgreen;
  }
}