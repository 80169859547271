.add-form {
  width: 400px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #ddd;
  padding: 10px;

  &__input__wrapper {
    margin-top: 30px;
  }

  h1 {
    text-align: center;
  }

  label {
    margin-top: 20px;
    font-weight: bold;
  }

  input,
  textarea {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 25px;
    padding: 10px;
  }

  button {
    display: block;
    margin: 10px auto;
    padding: 10px;
    width: 80%;
  }
}