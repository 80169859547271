@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  color: #333;
  box-sizing: border-box;
}

.app__wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}